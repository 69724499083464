import styled, { keyframes } from 'styled-components'

const shimmer = keyframes`
  100% { transform: translateX(100%); }
`

export default styled.div`
  .skeleton-box {
    width: ${props => props.width};
    display: inline-block;
    height: ${props => props.height};
    position: relative;
    overflow: hidden;
    background-color: #efefef;
    margin-bottom: 10px;
    display:flex;
    margin-left:${props => (props.center ? 'auto' : '0')}
    margin-right:${props => (props.center ? 'auto' : '0')}

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
      );
      animation: ${shimmer} 0.5s linear infinite;
      content: '';
    }
  }
`
