import React from 'react'
import StyleSkeleton from './index.style'

const Skeleton = ({ width = '100%', height = '1em', center = false }) => {
  return (
    <StyleSkeleton center={center} width={width} height={height}>
      <div className="skeleton-box"></div>
    </StyleSkeleton>
  )
}
export default Skeleton
